import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  retry,
  catchError,
  timeout,
  shareReplay,
  retryWhen,
  mergeMap,
  delay,
  take,
  switchMap,
  scan,
  takeWhile,
  concatMap,
} from "rxjs/operators";
import { EMPTY, Observable, concat, of, throwError, timer } from "rxjs";
import { v4 as uuidv4 } from "uuid";

@Injectable({ providedIn: "root" })
export class RESTService {
  maxRetries = 3;
  baseEndpoint = environment.endpoint;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "X-F-Id": this.generateUniqueId(),
    }),
  };

  constructor(private http: HttpClient) {}

  public head(resource: string, options?: any) {
    return this.http.head(this.baseEndpoint + resource, options);
  }
  public get(resource: string, options?: any) {
    if (resource === "/dashboard") {
      return this.getWithRetryLogic(resource);
    } else {
      return this.http.get(this.baseEndpoint + resource, options);
    }
  }

  public post(resource: string, data: any) {
    return this.http.post(this.baseEndpoint + resource, data, this.httpOptions);
  }
  public put(resource: string, data: any, options?: any) {
    return this.http.put(this.baseEndpoint + resource, data, options);
  }
  public patch(resource: string, data: any, options?: any) {
    return this.http.patch(this.baseEndpoint + resource, data, options);
  }
  public delete(resource: string, options?: any) {
    return this.http.delete(this.baseEndpoint + resource, options);
  }

  private getWithRetryLogic(resource: string, options?: any) {
    return this.http.get(this.baseEndpoint + resource, options).pipe(
      timeout(120000),
      retry(5),
      catchError((error) => {
        console.log(`request failed with ${error}`);
        throw error;
      })
    );
  }

  generateUniqueId(): string {
    return uuidv4();
  }
}
